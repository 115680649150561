import { MemoryRouter as Router, Route, Switch } from 'react-router-dom'
import Founder from './ProfileUI/FounderPage';
import Login from './AdminUI/LoginPage';
import Home from './ClientUI/HomePage';

function RoutePage() {
  if (window.location.host.split(".")[0] == 'nareennair') {
    return (
      <Router>
        <Switch>
          <Route path='/' exact={true} component={Founder} />
        </Switch>
      </Router>
    );
  }
  else if (window.location.host.split(".")[0] == 'manage') {
    return (
      <Router>
        <Switch>
          <Route path='/login' exact={true} component={Login} />
        </Switch>
      </Router>
    );
  }
  else {
    return (
      <Router>
        <Switch>
          <Route path='/' exact={true} component={Home} />
          <Route path='/login' exact={true} component={Login} />
          <Route path='/founder' exact={true} component={Founder} />
        </Switch>
      </Router>
    );
  }
}

export default RoutePage;