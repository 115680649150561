import { Component } from 'react';
import { Link } from 'react-router-dom'

class HomePage extends Component {
  state = {
    isLoading: true,
    result: []
  };

  async componentDidMount() {
    const response = (await fetch('/')).json;
    this.setState({ result: response, isLoading: false });
  }

  render() {
    const {result, isLoading} = this.state;

    if (isLoading) {
      return <p>Loading...</p>;
    }

    return (
      <div>
        <h2>Welcome to Login Page</h2>
        <Link to="/">Home</Link>
        <Link to="/login">Login</Link>
        <Link to="/founder">Founder</Link>
        <Link to="/founder"><button type="button">Click Me</button>
        </Link>
      </div>
    );
  }
}

export default HomePage;